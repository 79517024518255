import React from 'react';
import 'semantic-ui-less/semantic.less';
import './messageBanner.css'

export default class MessageBanner extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = { showModal: false, bannerVersion: '1.0' };
        this.closeModal = this.closeModal.bind(this);
        this.openModal = this.openModal.bind(this);
    }

    componentDidMount() {
      this.openModal();
    }

    closeModal(event) {
        event.stopPropagation();
        localStorage.setItem('fishermanMessageBannerShown', true);
        this.setState({ showModal: false })
    }

    openModal() {
        this.setState({ showModal: true })
    }

    render() {
        const { className = '' } = this.props;

        const messageBanner = `Message from Thai Amarin`;
        const messageString = `We missed you! We’re now open for DINE-IN`;
        return (
            <div className="message-banner-container" onClick={this.openModal}>
                <span className="message-banner-text">{messageBanner}</span>
                {!!this.state.showModal ? (<div className="message-banner-modal-container">
                    <div className="message-banner-modal-overlay">
                        <div className="message-banner-modal">
                            <div className="message-banner-modal-header">
                                Important message
                            </div>
                            <div className="message-banner-modal-body">
                                <p>{messageString}</p>
                            </div>
                            <div className="message-banner-modal-footer">
                                <button type="button" onClick={this.closeModal}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>) : null}
            </div>
        );
    }
}