import React from "react";
import {
  Navigation,
  Footer,
  CopyrightLabel,
  SiteMap,
  SocialMedia,
  PublishBanner
} from "@fishrmn/fishrmn-components";
import MessageBanner from "./MessageBanner/MessageBanner";
import { Link } from "gatsby";
import "semantic-ui-less/semantic.less";
import "./layout.css";

export default class Layout extends React.PureComponent {
  render() {
    const { className = "" } = this.props;
    return (
      <div className={className}>
        <Navigation
          links={[
            { pageTitle: "Home", slug: "/", internal: true },
            // { pageTitle: "Lunch", slug: "/lunch-menu/", internal: true },
            // { pageTitle: "Dinner", slug: "/dinner-menu/", internal: true },
            { pageTitle: "Contact", slug: "/contact/", internal: true },
            { pageTitle: "Gallery", slug: "/gallery/", internal: true },
            {
              pageTitle: "Order Online",
              slug: "https://ordering.chownow.com/order/10080/locations",
              internal: false
            },
            {
              pageTitle: "Catering",
              slug:
                "https://www.ezcater.com/catering/pvt/thai-amarin-newton?fcv=1",
              internal: false
            }
          ]}
          backgroundColor={"none"}
          bordered={false}
          centerMobileNavItems={true}
          evenSpacing={true}
          fixed={false}
          header={"Thai Amarin"}
          linksAs={"h3"}
          logo={
            "https://fisherman-static.s3.amazonaws.com/public/misc/IMG_8665.PNG"
          }
          primaryContentPosition={"top"}
          primaryContentCentered={false}
          internalLinkComponent={Link}
        />
        <div className="page-container">{this.props.children}</div>
        <Footer
          backgroundColor={"primary"}
          horizontalAlign={"center"}
          verticalAlign={"middle"}
          columns={[
            <CopyrightLabel
              company={"Thai Amarin"}
              phrase={"All Rights Reserved"}
            />,
            <SocialMedia
              socialMediaValues={[
                {
                  type: "yelp",
                  link: "https://www.yelp.com/biz/thai-amarin-newton-corner",
                  label: null
                },
                {
                  type: "facebook",
                  link: "https://www.facebook.com/thaiamarinnewton/",
                  label: null
                },
                {
                  type: "instagram",
                  link: "https://instagram.com/thaiamarin?igshid=ra1qfu5vp42j",
                  label: null
                }
              ]}
              buttonType={"circle"}
              centerHeader={true}
              colors={"secondary"}
              groupButtons={false}
              groupVertically={false}
              header={""}
              inverted={false}
              labelPosition={"left"}
              showLabels={false}
            />
          ]}
        />
      </div>
    );
  }
}
